<template>
	<ion-page>
		<ion-header v-if="!videoFullscreen" :class="{'mobile-version': !$isVideoWeb.value}">
			<ion-toolbar v-if="!$isVideoWeb.value" class="no-shadow">
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />
			</ion-toolbar>

			<ion-toolbar v-else>
				<ion-back-button text="Academy" slot="start" :defaultHref="{name: 'home'}" />

				<!-- <ion-title>
					<ion-searchbar class="header-search web-version" placeholder="Vyhľadaj" show-cancel-button="never"></ion-searchbar>
				</ion-title> -->

				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content :scroll-events="true" @ionScroll="contentScroll" :fullscreen="!$isVideoWeb.value" :force-overscroll="false" :class="{'mobile-version': !$isVideoWeb.value}">
			<w-loadable v-if="!videos" :items="videos" skelet="spinner" class="loadable" />
			<A-tiktok-feed v-else @toggleVideoFullscreen="_toggleVideoFullscreen" :videos="videos" :disableFirstVideoAutoplay="false" :showWebVideo="$isVideoWeb.value"
				@update="updateVideos($event)" ref="a-tiktok-feed" />
			<A-fullscreen-video class="fullscreen-video" v-if="videoFullscreen" @closeFullscreen="_toggleVideoFullscreen" :videos="videos" :currentVideoIndex="currentVideoIndex" />
		</ion-content>
	</ion-page>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import ATiktokFeed from '@/plugins/lib@feed/_components/a-feed.vue'
import AFullscreenVideo from '@/plugins/lib@feed/_components/a-fullscreen-video.vue'
import { personCircleOutline, logInOutline } from 'ionicons/icons'
import { mapGetters} 		from 'vuex'

const api = {
	read: (userId, model) => wAxios.get_auth_data(`v1/users/${userId}/lessons?types=${model}`)
}

export default {
	components: {
		ATiktokFeed,
		AFullscreenVideo
	},
	
	data() {
		return {
			videos: null,
			personCircleOutline, 
			logInOutline,
			disableScroll: false,
			videoFullscreen: false,
			currentVideoIndex: 0,
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	methods: {

		_toggleVideoFullscreen(videoIndex) {
			this.currentVideoIndex = videoIndex
			if (!this.videoFullscreen) {
				this.videoFullscreen = true
				this._toggleScroll()
			} else {
				this.videoFullscreen = false
				this._toggleScroll()
			}
		},

		_toggleScroll() {
			if (!this.disableScroll){
				this.disableScroll = true
			} else {
				this.disableScroll = false
			}
			console.log("scroll toggled")
		},

		contentScroll() {
			if (this.$isVideoWeb.value) this.$refs['a-tiktok-feed']?._playWebVideo()
		},

		async ionViewDidEnter() {
			await this._onEnter()

			// this.eventBus.on('video:update', (video) => {
			// 	if (this.videos) 
			// 	let targetVideo = this.videos.find(v => v.id == video.id)
			// 	targetVideo = video
			// })
			
			this.$refs['a-tiktok-feed']?._playCurrentVideo()
		},

		ionViewWillLeave() {
			this.videos = null
			// this.$refs['a-tiktok-feed']?._pauseAllVideosSilently()
		},

		async _onEnter() {
			const videos = await api.read(this.$route.params.userId, this.$route.params.type)

			// Temporary commented to fix animation, replaced by set timeout below this
			// this.videos = course.lessons
			// setTimeout(() => {
			// 	this.$refs['a-tiktok-feed'].scrollToVideoByVideoId(this.$route.params.videoId)
			// })

			setTimeout(() => {
				this.videos = videos.filter(video => video.content_shot)

				this.$nextTick(() => {
					this.$refs['a-tiktok-feed'].scrollToVideoByVideoId(this.$route.params.videoId)
				})
			}, 500)
		},

		updateVideos($event) {
			this.videos[$event.index] = $event
		}
	},
	
	watch: {
		$route(to) {
			if (to.name == 'feed-profile-videos') this._onEnter()
		}
	}
}
</script>

<style lang="sass" scoped>
.fullscreen-video
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: 999

ion-header.mobile-version
	position: absolute
	top: 0

	ion-toolbar
		--background: transparent !important

	ion-back-button,
	ion-label
		color: white !important

ion-content.mobile-version
	--background: var(--ion-color-dark)

ion-content
	scroll-content
		--margin-top: 0px	
</style>
